import React, { useState, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { createPortal } from 'react-dom';

import { Box } from '../Box';
import { Text } from '../Text';
import { Icons } from '../Icons';
import { Button } from '../Button';
import { Skeleton } from '../Skeleton';
import { getBaseUnit } from '../styles/themeGetters';

import { useClickOutside } from '../utils/useClickOutside';
import { AnimatedModalMountWrapper } from './components/AnimatedModalWrapper';

const ModalContent = styled(Box)<{ maxHeight?: string; maxWidth?: string }>`
  position: relative;
  border-radius: 12px;
  min-width: ${({ minWidth }) => minWidth && minWidth};
  min-height: ${({ minHeight }) => minHeight && minHeight};
  max-height: ${({ maxHeight }) => maxHeight || '100vh'};
  max-width: ${({ maxWidth }) => maxWidth && maxWidth};
  flex-direction: column;
  overflow: ${({ maxHeight }) => maxHeight && 'auto'};
`;

const ModalBody = styled(Box)`
  max-height: 100%;
  overflow: auto;
`;

const ModalFooter = styled(Box)`
  padding-top: ${getBaseUnit(5)};
  margin-top: auto;
  text-align: end;
`;

const ModalLoader = styled(Box)`
  overflow-y: auto;
  max-height: 100%;
  margin-top: 20px;
`;

const useModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // memoize useModal functions so it doesn't change the function reference when calling inside useEffects
  const openModal = useCallback(() => setIsModalOpen(true), []);
  const closeModal = useCallback(() => setIsModalOpen(false), []);

  return {
    openModal,
    closeModal,
    isModalOpen,
  };
};

const StyledClosingWrapper = styled.div`
  display: flex;
`;

const LoadingWrapper = () => (
  <ModalLoader>
    <Box pb={3}>
      <Skeleton width="100%" height="20px" />
    </Box>
    <Box pb={3}>
      <Skeleton width="75%" height="20px" />
    </Box>
    <Box pb={3}>
      <Skeleton width="50%" height="20px" />
    </Box>
    <Box pb={3}>
      <Skeleton width="75%" height="20px" />
    </Box>
  </ModalLoader>
);

type ClosingWrapperProps = {
  children: React.ReactNode;
  onClickOutside: () => void;
  prioritizeAnchor?: boolean;
};

const ClosingWrapper = ({
  children,
  onClickOutside,
  prioritizeAnchor = false,
}: ClosingWrapperProps) => {
  const ref = useRef(null);

  useClickOutside({
    targetRef: ref,
    onClickOutside,
    prioritizeAnchor,
    eventType: 'mousedown',
  });

  return <StyledClosingWrapper ref={ref}>{children}</StyledClosingWrapper>;
};

type ModalProps = {
  root?: string;
  minHeight?: string;
  maxHeight?: string;
  minWidth?: string;
  maxWidth?: string;
  onCloseModal: () => void;
  isModalOpen: boolean;
  isModalLoading?: boolean;
  title: string | React.ReactNode;
  subTitle?: string | React.ReactNode;
  ariaLabel: string;
  pl?: number;
  pr?: number;
  pt?: number;
  pb?: number;
  truncateTitle?: boolean;
  prioritizeAnchor?: boolean;
  children: React.ReactNode;
  footer: React.ReactNode;
};

const Modal = ({
  onCloseModal,
  isModalOpen,
  isModalLoading = false,
  root = 'modal',
  title,
  subTitle,
  minHeight,
  maxHeight,
  minWidth = '100px',
  maxWidth = '1200px',
  ariaLabel,
  pl,
  pr,
  pt,
  pb,
  truncateTitle = true,
  prioritizeAnchor = false,
  children,
  footer,
}: ModalProps) => {
  return createPortal(
    <AnimatedModalMountWrapper isModalOpen={isModalOpen} ariaLabel={ariaLabel}>
      <ClosingWrapper
        onClickOutside={onCloseModal}
        prioritizeAnchor={prioritizeAnchor}
      >
        <ModalContent
          elevation={2}
          backgroundColor="white"
          pl={pl || 10}
          pr={pr || 10}
          pt={pt || 10}
          pb={pb || 10}
          minWidth={minWidth}
          maxWidth={maxWidth}
          minHeight={minHeight}
          maxHeight={maxHeight}
          display="flex"
        >
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Text
              fontSize={24}
              fontWeight="bold"
              mb={0}
              truncated={truncateTitle}
            >
              {title}
            </Text>
            <Button
              size="s"
              variant="text"
              onClick={onCloseModal}
              startIcon={Icons.Close}
              aria-label="close drawer"
            />
          </Box>
          {isModalLoading ? (
            <LoadingWrapper />
          ) : (
            <>
              {subTitle && <Text>{subTitle}</Text>}
              <ModalBody>{children}</ModalBody>
              <ModalFooter>{footer}</ModalFooter>
            </>
          )}
        </ModalContent>
      </ClosingWrapper>
    </AnimatedModalMountWrapper>,
    document.getElementById(root)
  );
};

export { Modal, useModal };
