import { debounce } from 'lodash';
import { Suggestion } from './types';

const debouncedFetch = debounce(async (apiCall, callback) => {
  try {
    const data = await apiCall();

    callback(data);
  } catch (error) {
    callback([]);
  }
}, 250);

export const debouncedSuggestions = async (
  apiCall: () => Promise<Suggestion[]>
): Promise<Suggestion[]> => {
  return new Promise(resolve => {
    debouncedFetch(apiCall, results => {
      resolve(results);
    });
  });
};
