import {
  getRequestV2,
  postRequestV2,
  patchRequestV2,
  deleteRequestV2,
} from '../methods';

type StrategicElementData = {
  title: string;
  description: string;
  kind: 'team' | 'organization';
  owners: { ownerType: string; ownerId: number }[];
};

type LinkEntityLink = {
  sourceType: 'StrategicElement';
  sourceId: number;
  targetType: 'StrategicElement';
  targetId: number;
};

type UnlinkEntityLink = {
  id: number;
  _destroy: boolean;
};

export type EntityLink = LinkEntityLink | UnlinkEntityLink;

const createStrategicElement = (payload: {
  strategicElement: StrategicElementData;
}) => postRequestV2('strategic_elements', payload);

const getStrategicElements = (
  cycleId: number,
  ownerType: string,
  ownerId?: string
) =>
  getRequestV2(
    `strategic_elements?cycle_id=${cycleId}&owner_type=${ownerType}${
      ownerId ? `&owner_id=${ownerId}` : ''
    }`
  );

const getStrategicElement = (strategicElementId: number) =>
  getRequestV2(`strategic_elements/${strategicElementId}`);

const getStrategicElementTypes = () =>
  getRequestV2(`strategic_elements/categories`);

const editStrategicElement = (
  strategicElementId: number,
  payload: {
    strategicElement: StrategicElementData;
  }
) => patchRequestV2(`strategic_elements/${strategicElementId}`, payload);

const deleteStrategicElement = (strategicElementId: number) =>
  deleteRequestV2(`strategic_elements/${strategicElementId}`);

const linkStrategicElements = (payload: { entityLinks: EntityLink[] }) =>
  postRequestV2(`entity_links/bulk_upsert`, payload);

const strategicElements = {
  createStrategicElement,
  getStrategicElements,
  getStrategicElement,
  editStrategicElement,
  deleteStrategicElement,
  linkStrategicElements,
  getStrategicElementTypes,
};

export default strategicElements;
