import SparkMD5 from 'spark-md5';

const readFileAsBinaryStringAsync = (
  file: File
): Promise<FileReader['result']> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);

    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

/** Generates a file checksum to use with S3 direct uploads.
 * Steps:
 *   1- converts the file to a binary string
 *   2- generates a MD5 hash from that binary string
 *   3- encodes the MD5 hash to a Base64 string
 */
const getS3UploadChecksum = async (file: File) => {
  const spark = new SparkMD5();
  const fileAsBinary = await readFileAsBinaryStringAsync(file);

  spark.appendBinary(fileAsBinary);
  const binaryDigest = spark.end(true);
  return window.btoa(binaryDigest);
};

export { getS3UploadChecksum };
