import React from 'react';

import { Team } from 'types/Team';

import { Avatar } from '../Avatar';

export type TeamAvatarProps = {
  team: Pick<Team, 'name' | 'archived'>;
  size: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
  onClick?: () => void;
};

/**
 * This component is a wrapper around Avatar.
 * It is used to display a team avatar.
 * This component centralizes the logic for displaying a team avatar (e.g. the status).
 * @param team The team to display the avatar for.
 * @param size The size of the avatar.
 * @param onClick The function to call when the avatar is clicked.
 * @returns The rendered component.
 * @example
 * <TeamAvatar team={team} size="l" onClick={handleClick} />
 */

const TeamAvatar = ({ team, size, onClick }: TeamAvatarProps) => (
  <Avatar
    type="team"
    fallbackText={team.name}
    size={size}
    status={team.archived ? 'archived' : undefined}
    onClick={onClick}
  />
);

export { TeamAvatar };
