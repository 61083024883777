import { ReactRenderer } from '@tiptap/react';
import tippy from 'tippy.js';
import { MentionList } from './MentionList';
import { debouncedSuggestions } from './debouncedSuggestions';
import { SuggestionsClient } from './types';

export const renderSuggestions = ({
  suggestionsClient,
  loadingMessage,
  noMatchesMessage,
}: {
  suggestionsClient: SuggestionsClient;
  loadingMessage: string;
  noMatchesMessage: string;
}) => ({
  items: ({ query }) => {
    if (query.length === 0) {
      return [];
    }

    return debouncedSuggestions(() =>
      suggestionsClient.fetchSuggestions(query)
    );
  },
  render: () => {
    let component;
    let popup;

    return {
      onStart: props => {
        component = new ReactRenderer(MentionList, {
          props: {
            ...props,
            noMatchesMessage,
            loadingMessage,
            isLoading: false,
          },
          editor: props.editor,
        });

        if (!props.clientRect) {
          return;
        }

        [popup] = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.querySelector('.mentions-text-area'),
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        });
      },

      onBeforeUpdate: props => {
        component.updateProps({ ...props, isLoading: true });
      },

      onUpdate(props) {
        component.updateProps({ ...props, isLoading: false });

        if (!props.clientRect) {
          return;
        }

        popup.setProps({
          getReferenceClientRect: props.clientRect,
        });
      },

      onKeyDown(props) {
        props.event.stopPropagation();

        if (props.event.key === 'Escape') {
          popup.hide();

          return true;
        }

        return component.ref?.onKeyDown(props);
      },

      onExit() {
        popup.destroy();
        component.destroy();
      },
    };
  },
});
