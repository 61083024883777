import React, { ChangeEvent, useRef, useState } from 'react';
import { getBaseUnit } from '../../styles/themeGetters';
import { InputControl } from '../components/InputControl';
import { InputField } from '../components/InputField';
import { InputFieldClearButton } from '../components/InputFieldClearButton';
import { ClearButtonFeature, CommonProps } from '../types';
import { InputFileField } from './InputFileField';
import { Skeleton } from '../../Skeleton';
import { Box } from '../../Box';

type UploadedFile = string;
type InputFileProps = {
  onFileUpload: (file: File) => Promise<UploadedFile>;
  accept?: 'image/*' | 'audio/*' | 'video/*' | string;
} & CommonProps &
  ClearButtonFeature;

type State =
  | {
      status: 'uploaded';
      value: UploadedFile;
    }
  | {
      status: 'empty' | 'uploading';
      value?: never;
    };

const createSyntheticEvent = (value: string, name: string) => ({
  target: {
    value,
    name,
  },
});

const InputFile = (props: InputFileProps) => {
  const {
    name,
    onFileUpload,
    error,
    disabled,
    helpText,
    extraHelpText,
    label,
    hasClearButton,
    validations = {},
    formHooks: { register, resetField },
    condensed,
    accept,
  } = props;

  const [state, setState] = useState<State>({
    status: 'empty',
  });

  const showClearButton = Boolean(
    hasClearButton && state.status === 'uploaded'
  );

  const { onChange } = register(name, validations);

  const onFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0];
    setState({ status: 'uploading' });
    const uploadedFileSignedId = await onFileUpload(file);
    setState({ status: 'uploaded', value: uploadedFileSignedId });
    onChange(createSyntheticEvent(uploadedFileSignedId, name));
  };
  const fileFieldRef = useRef<HTMLInputElement>(null);

  const onFileReset = () => {
    setState({ status: 'empty' });
    if (resetField) {
      resetField(name);
    }
    if (fileFieldRef.current) {
      fileFieldRef.current.value = '';
    }
  };

  return (
    <InputControl
      error={error}
      helpText={helpText}
      extraHelpText={extraHelpText}
      label={label}
      htmlFor={name}
      condensed={condensed}
    >
      <InputField
        hasError={Boolean(error)}
        disabled={disabled}
        paddingRight={showClearButton ? '5px' : '11px'}
        paddingLeft="0"
      >
        <Box width="100%" display="flex" justifyContent="space-between">
          <InputFileField
            disabled={disabled}
            onChange={onFileChange}
            ref={fileFieldRef}
            name={name}
            accept={accept}
          />
          {showClearButton && (
            <InputFieldClearButton
              onClick={onFileReset}
              disabled={disabled}
              align="center"
            />
          )}
          {state.status === 'uploading' && (
            <Box alignSelf="center">
              <Skeleton width={getBaseUnit(6)} height={getBaseUnit(6)} />
            </Box>
          )}
        </Box>
      </InputField>
    </InputControl>
  );
};

export { InputFile };
