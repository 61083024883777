import { Content } from '@tiptap/core';

/**
 * Transforms given content to be JSON compatible with the Tiptap editor for rendering.
 *
 * This function takes a string that could contain user mentions in a specific JSON format
 * and replaces each mention with Tiptap compatible JSON
 *
 * @param {string} content - A string that could contain user mentions in JSON format.
 * @returns {Content} Returns a JSON to be used by the Tiptap editor for rendering
 */
export const transformToTipTapJSON = (content?: string): Content => {
  if (!content) {
    return null;
  }

  const contentRows = content.split('\n');

  return contentRows.map(row => {
    if (row.length === 0) {
      return { type: 'paragraph' };
    }

    return getJSONRow(row);
  });
};

const getJSONRow = (row: string) => {
  const regex = /({{user_mention\|{.*?}}})/g;
  const isRowWithMentions = regex.test(row);

  if (isRowWithMentions) {
    const splittedByMentions = row
      .split(/({{user_mention\|{.*?}}})/g)
      .filter(text => text !== '');

    return {
      type: 'paragraph',
      content: getJSONRowWithMentions(splittedByMentions),
    };
  }

  return { type: 'paragraph', content: [{ type: 'text', text: row }] };
};

const getJSONRowWithMentions = (splittedByMentions: string[]) => {
  return splittedByMentions.map(section => {
    const mentionMatch = section.match(/{{user_mention\|({.*?})}}/);

    if (mentionMatch) {
      const user = getUserParsed(mentionMatch[1]);

      if (user.id) {
        return {
          type: 'mention',
          attrs: { id: user.id, label: `${user.first_name} ${user.last_name}` },
        };
      }
    }

    return { type: 'text', text: section };
  });
};

const getUserParsed = (userMatch: string) => {
  try {
    return JSON.parse(userMatch);
  } catch (error) {
    return {};
  }
};
