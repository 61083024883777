import React, { useEffect, useRef } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import Text from '@tiptap/extension-text';
import Mention from '@tiptap/extension-mention';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import styled from 'styled-components';

import { renderSuggestions } from './suggestion';
import * as Theme from '../../styles/themeGetters';
import { transformToTipTapJSON } from './utils/transformToTipTapJSON';
import { transformTipTapMentionsForServer } from './utils/transformTipTapMentionsForServer';

import { SuggestionsClient } from './types';

type MentionsTextAreaWrapperProps = {
  height?: string;
};

const MentionsTextAreaWrapper = styled.span<MentionsTextAreaWrapperProps>`
  .tiptap {
    outline: none;
    border-radius: 4px;
    padding: 8px 12px;
    height: 158px;
    overflow: scroll;
    border: 1px solid ${Theme.getColor('grey-light-100')};
    background: ${Theme.getColor('white')};
    line-height: ${Theme.getBaseUnit(6)};

    .mention {
      background-color: ${Theme.getColor('grey-light-100')};
      border-radius: 7px;
      color: ${Theme.getColor('blue-dark')};
      padding: 1px 4px 2px 4px;
    }

    // reset tiptap paragraph styles
    & p {
      margin: 0;
    }
  }
`;

export type RawMentionsTextAreaProps = {
  content?: string;
  loadingMessage: string;
  noMatchesMessage: string;
  suggestionsClient: SuggestionsClient;
  onUpdate?: (value: string) => void;
};

export const RawMentionsTextArea = ({
  content = '',
  onUpdate,
  loadingMessage,
  noMatchesMessage,
  suggestionsClient,
}: RawMentionsTextAreaProps) => {
  const initialContentRef = useRef(content);
  const mentionsEditor = useEditor({
    extensions: [
      Document,
      Paragraph,
      Text,
      Mention.configure({
        HTMLAttributes: {
          class: 'mention',
        },
        suggestion: renderSuggestions({
          suggestionsClient,
          loadingMessage,
          noMatchesMessage,
        }),
      }),
    ],
    editorProps: {
      attributes: {
        id: 'mentions-text-area',
        'data-testid': 'mentions-text-area',
      },
    },
    onUpdate: ({ editor }) => {
      onUpdate(transformTipTapMentionsForServer(editor.getJSON()));
    },
  });

  useEffect(() => {
    // According to the Tiptap rendering cycle, if we want to synchronize our React state with Tiptap,
    // we need to set the content after the editor has been initialized
    if (content?.length) {
      mentionsEditor.commands.setContent(transformToTipTapJSON(content));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- [RFC 032], mentionsEditor.commands deps will cause infinite rerenders
  }, [initialContentRef.current !== content]);

  useEffect(
    () => () => mentionsEditor.destroy(),
    // eslint-disable-next-line react-hooks/exhaustive-deps -- [RFC 032], mentionsEditor.commands deps will cause infinite rerenders
    []
  );

  if (!mentionsEditor) {
    return null;
  }

  return (
    <MentionsTextAreaWrapper className="mentions-text-area">
      <EditorContent editor={mentionsEditor} />
    </MentionsTextAreaWrapper>
  );
};
