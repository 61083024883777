import { CurrentAccount } from 'types/CurrentAccount';

import {
  AccountFeatureFlagName,
  AccountFeatureFlagState,
  AccountFeatureFlagMap,
} from 'types/AccountFeatureFlags';
import { accessAccountFeatureFlagMap, Awaitable } from './util';
import { useCurrentAccount } from './baseState/useCurrentAccount';

type AwaitableAccountFeatureFlagState = Awaitable<AccountFeatureFlagState>;

const useCurrentAccountAwaitableWithTransform: <TData>(
  transform: (t: CurrentAccount) => TData
) => Awaitable<TData> = transform => {
  const currentAccount = useCurrentAccount();
  if (currentAccount) {
    return { loading: false, data: transform(currentAccount) };
  }
  return { loading: true };
};

export const useGetAccountFeatureStatus = (
  featureName: AccountFeatureFlagName
): AwaitableAccountFeatureFlagState =>
  useCurrentAccountAwaitableWithTransform(
    account => accessAccountFeatureFlagMap(account)[featureName]
  );

export const useHasAccountFeatureFlagActive = (
  featureName: AccountFeatureFlagName
): boolean => {
  const awaitable = useGetAccountFeatureStatus(featureName);
  return awaitable.loading === false && awaitable.data === 'active';
};

export const useHasAccountFeatureFlagInactive = (
  featureName: AccountFeatureFlagName
): boolean => {
  const awaitable = useGetAccountFeatureStatus(featureName);
  return awaitable.loading === false && awaitable.data === 'inactive';
};

export const useAccountFeatureFlagsAwaitable = (): Awaitable<AccountFeatureFlagMap> => {
  return useCurrentAccountAwaitableWithTransform(account =>
    accessAccountFeatureFlagMap(account)
  );
};

export const useAccountTranslationsAwaitable = (): Awaitable<CurrentAccount['translations']> => {
  return useCurrentAccountAwaitableWithTransform(
    account => account.translations
  );
};

export const useAccountTranslations = (): CurrentAccount['translations'] => {
  const awaitable = useAccountTranslationsAwaitable();
  return awaitable.loading === false ? awaitable.data : {};
};
