import { getS3UploadChecksum } from 'utils/checksum';
import { postRequestV2 } from '../../methods';

type BlobDescriptorPayload = {
  blob: {
    filename: string;
    byteSize: number;
    checksum: string;
    contentType: string;
  };
};

type UploadDescriptor = {
  url: string;
  headers: {
    contentDisposition: string;
    contentMD5: string;
    contentType: string;
  };
  signedId: string;
};

type ServiceName = 'avatars' | 'strategic_elements';

const buildPayload = async (file: File): Promise<BlobDescriptorPayload> => {
  const checksum = await getS3UploadChecksum(file);

  return {
    blob: {
      filename: file.name,
      byteSize: file.size,
      checksum,
      contentType: file.type,
    },
  };
};

const uploadToS3Bucket = (upload: UploadDescriptor, file: File) => {
  const { url, headers, signedId } = upload;

  return fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Disposition': headers.contentDisposition,
      'Content-MD5': headers.contentMD5,
      'Content-Type': headers.contentType,
    },
    body: file,
  }).then(() => signedId);
};

const getSignedUrl = (
  serviceName: ServiceName,
  payload: BlobDescriptorPayload
): Promise<{ upload: UploadDescriptor }> =>
  postRequestV2(`direct_uploads/${serviceName}`, payload);

// https://aws.amazon.com/blogs/compute/uploading-to-amazon-s3-directly-from-a-web-or-mobile-application/
const directUpload = async (serviceName: ServiceName, file: File) => {
  const payload = await buildPayload(file);

  const response = await getSignedUrl(serviceName, payload);
  return uploadToS3Bucket(response.upload, file);
};

const directUploadAvatar = (file: File) => directUpload('avatars', file);
const directUploadStrategicElementsImage = (file: File) =>
  directUpload('strategic_elements', file);

const uploads = {
  directUpload,
  directUploadAvatar,
  directUploadStrategicElementsImage,
};

export default uploads;
